import {AbstractControl, ValidationErrors} from '@angular/forms';
import {MsDate} from '@matchsource/date';
import {isEmpty} from '@matchsource/utils';

export const DATE_VALIDATOR_KEY = 'valid-date';

export const dateValidator = (control: AbstractControl): ValidationErrors | null => {
  const isValid = isEmpty(control.value) || new MsDate(control.value).isValid;

  return !isValid ? {[DATE_VALIDATOR_KEY]: {value: control.value}} : null;
};
